/**
 * List contents.
 */
import { apiDelete, apiGet, apiPatch } from '@/util/api';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'Contents',
  components: {
    Icon,
  },
  data() {
    return {
      contents: [],
      filter: '',
      contentsLoaded: false,
      curators: [
        {
          text: this.$t('contents_filter_curator'),
          value: null,
          disabled: true,
        },
      ],
      filterCurator: null,
      filterPublished: false,
      loading: true,
      contentsTablePerPage: 10,
      contentsTableCurrentPage: 1,
      contentsTableFields: [
        {
          key: 'name',
          sortable: true,
          label: this.$t('contents_list_content_name'),
        },
        {
          key: 'curator.name',
          sortable: true,
          label: this.$t('contents_list_curator'),
        },
        {
          key: 'created',
          sortable: true,
          label: this.$t('contents_list_created_d'),
          formatter: v => {
            return new Date(v).toLocaleDateString();
          },
        },
        {
          key: 'published',
          sortable: true,
          label: this.$t('contents_list_published_d'),
          formatter: v => {
            return new Date(v).toLocaleDateString();
          },
        },
        { key: 'publish', label: '', thStyle: { width: '150px' } },
        {
          key: 'publishedHome',
          label: '',
          class: 'table-user-control',
        },
        {
          key: 'delete',
          label: '',
          class: 'table-user-control',
        },
      ],
      sortBy: 'created',
      sortDesc: true,
      deleteContent: {},
    };
  },
  async created() {
    // Get the list of contents from the API.
    try {
      this.contents = await apiGet(
        '/content?orderBySort=created&orderByOrder=DESC&unpublished=true&thumbnails=false',
      );
      this.contents.forEach(content => {
        if (!content.curator) {
          content.curator = {
            name: this.$t('contents_no_curator'),
            userId: 'none',
          };
        }

        if (
          !this.curators.some(
            curator => curator.value === content.curator.userId,
          )
        ) {
          this.curators.push({
            text: content.curator.name,
            value: content.curator.userId,
          });
        }
      });
    } catch (error) {
      this.$bvToast.toast(this.$t('clents_error_get_contents'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
      this.contentsLoaded = true;
    }
  },
  computed: {
    /**
     * Generate list of content to display to the user.
     * Applies text filter to the list.
     */
    contentList() {
      this.contentsTableCurrentPage = 1;
      let contents = this.contents;

      if (this.filterPublished)
        contents = contents.filter(content => content.published);

      if (!this.filter && this.filterCurator === null) return contents;

      if (this.filterCurator) {
        contents = contents.filter(content => {
          return content.curator.userId === this.filterCurator;
        });
      }

      if (!this.filter) return contents;
      const filter = this.filter.toLowerCase().split(' ');

      return contents.filter(c => {
        let query = `${c.name.toLowerCase()}${c.description.toLowerCase()}${c.curator.name.toLowerCase()}`;
        query = query.replace(/\s/g, '');

        return filter.every(word => query.includes(word.trim()));
      });
    },
  },
  methods: {
    /**
     * Confirm permanent deletion of a content.
     * @param {Number}  contentId
     */
    confirmDeleteContent(contentId) {
      this.deleteContent = this.contents.find(c => {
        return c.id === contentId;
      });
      this.$bvModal.show('content-confirm-delete');
    },

    /**
     * Handle the API request which deletes the content.
     */
    async doDeleteContent() {
      this.loading = true;
      await apiDelete(`/content/${this.deleteContent.id}`)
        .then(() => {
          // Remove the content from the list
          const contentIndex = this.contents.findIndex(
            c => c.id === this.deleteContent.id,
          );
          this.contents.splice(contentIndex, 1);
          this.$bvToast.toast(
            this.$t('contents_success_delete_content', {
              contentName: this.deleteContent.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('contents_error_delete_content', {
              contentName: this.deleteContent.content.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Handle the API request which publishes the content.
     */
    async publishContent(content) {
      content.published = true;
      this.loading = true;
      await apiPatch(`/content/publish/${content.id}`)
        .then(() => {
          this.$bvToast.toast(
            this.$t('contents_success_publish_content', {
              contentName: content.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('contents_error_publish_content', {
              contentName: content.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Handle the API request which publishes the content.
     */
    async unpublishContent(content) {
      content.published = false;
      this.loading = true;
      await apiPatch(`/content/unpublish/${content.id}`)
        .then(() => {
          this.$bvToast.toast(
            this.$t('contents_success_unpublish_content', {
              contentName: content.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('contents_error_unpublish_content', {
              contentName: content.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Set whether the content can be viewed on the homepage by non logged-in users.
     * @param {Number} contentId
     * @param {Boolean} allow
     */
    async allowOnHomepage(content, allow) {
      this.loading = true;

      await apiPatch(`/content/publishhome/${content.id}`, {
        availableForLoginPage: allow,
      })
        .then(() => {
          content.availableForLoginPage = allow;
          this.$bvToast.toast(
            this.$t(
              allow
                ? 'contents_success_published_home'
                : 'contents_success_unpublished_home',
              {
                contentName: content.name,
              },
            ),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('contents_error_set_publish_home', {
              contentName: content.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Clear the content filter variable.
     */
    clearFilter() {
      this.filter = '';
    },

    /**
     * Clear the curator filter variable.
     */
    clearCuratorFilter() {
      this.filterCurator = null;
    },
  },
};
